<template>
    <div>
        <div v-if="isPhone">
            <v-sheet color="white" elevation="4" width="100%" :style="phoneHeightStyle('Box')" class="py-3">
                <div
                    class="d-flex justify-space-between align-center pb-3"
                    :class="(isSelect ? 'p' : 'm') + 'x-6'"
                    style="border-bottom: 0.5px solid #c5c5c5">
                    <div class="d-flex" v-if="isSelect && $vuetify.breakpoint.smAndDown">
                        <v-checkbox color="card" v-model="isSelectAll"
                            @click="$emit('selectAll')" hide-details class="pt-0 mt-0"
                            :indeterminate=" selected.length != list.length && selected.length != 0"
                            :class="list.length == 0 ? 'disable' : ''"/>
                        <span class="pl-3">全選</span>
                    </div>
                    <slot v-else />
                    <div class="ml-auto">
                        <div class="total-transactions">
                            <span v-text="isSelect ? selected.length : list.length" />
                        </div>
                    </div>
                </div>
                <v-sheet width="100%" class="select" :style="phoneHeightStyle('Scroll')">
                    <h2 v-if="list.length == 0" :style="phoneNoTicketsStyle">尚無單據</h2>
                    <template v-for="(info, index) in list">
                        <div :key="`item-${index}`" @click="select(info.id)" class="select_item">
                            <div :class="comparator(info.id) ? 'card' : ''" class="select_bg"/>
                            <div class="d-flex align-center select_content">
                                <v-btn icon color="card" class="pl-7" v-if="isSelect">
                                    <v-icon v-text="`mdi-checkbox${comparator(info.id) ? '-marked' : '-blank-outline'}`" />
                                </v-btn>
                                <div class="select_text px-5">
                                    <p class="mb-0 text-no-wrap" v-text="`停車單號: ${info.id}`" />
                                    <p class="mb-0 text-no-wrap" v-text="`停車日期: ${info.parking_date || '--'} ${info.parking_time || ''}`"/>
                                    <p v-if="!isResult" class="mb-0" v-text="`繳費期限: ${info.expiry_date || '--'}`"/>
                                    <div class="d-flex justify-space-between">
                                        <h4 v-text="isResult ? '' : info.license_plate" />
                                        <p class="font-weight-bold mb-0"
                                            style="font-size: 1.2rem">
                                            $ {{ info.actual_fee.toLocaleString() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider
                            v-if="index != list.length - 1" :key="`line-${index}`"
                            class="mt-n2 pb-2"
                            :class="isSelect ? '' : 'mx-5'"/>
                    </template>
                </v-sheet>
                <div
                    class="text-right mb-3 pt-1"
                    :class="(isSelect ? 'p' : 'm') + 'x-6'"
                    style="border-top: 0.5px solid #c5c5c5">
                    <h3>$ {{ totalAmount.toLocaleString() }}</h3>
                </div>
            </v-sheet>
        </div>
        <v-sheet v-else class="scroll-box px-2 py-1" :style="pcHeightStyle('Box')" :elevation="shadow ? 4 : 0">
            <v-sheet class="scroll" :style="pcHeightStyle('Scroll')">
                <v-row v-if="list.length > 0">
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 4" v-for="info in list" :key="info.id" class="px-3 py-5">
                        <div class="pointer" :class="selectClass(info.id)" @click="select(info.id)">
                            <h4 class="mb-0 text-no-wrap" v-text="info.id" />
                            <div class="mt-2">
                                <p class="mb-0 text-no-wrap" v-text="`停車日期: ${info.parking_date || '--'} ${info.parking_time || ''}`"/>
                                <p v-if="!isResult" class="mb-0" v-text="`繳費期限: ${info.expiry_date || '--'}`"/>
                                <div class="d-flex justify-space-between align-center">
                                    <h4 class="mb-0" v-text="isResult ? '' : info.license_plate" />
                                    <p class="font-weight-bold mb-0"
                                        style="font-size: 1.2rem">
                                        $ {{ info.actual_fee.toLocaleString() }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <div v-else>
                    <h2 class="text-center" style="color: #a7a7a7" v-text="hintText" />
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>

<script>
export default {
    model: { prop: "selected", event: "change" },
    props: {
        isSelect: {
            type: Boolean,
            default: false
        },
        isResult: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Array,
            default: () => []
        },
        list: {
            type: Array,
            default: []
        },
        pcHeight: {
            type: Number,
            default: 400
        },
        phoneAddHeight: {
            type: Number,
            default: 0
        },
        shadow: {
            type: Boolean,
            default: false
        },
        hintText: {
            type: String,
            default: '尚無單據'
        },
    },
    data() {
        return {
            isSelectAll: false
        }
    },
    computed: {
        totalAmount() {
            let amount = 0
            if (this.isSelect) {
                for (const i of this.selected) {
                    amount += _.find(this.list, { id: i }).actual_fee
                }
            } else {
                amount = _.sumBy(this.list, "actual_fee")
            }
            return amount.toLocaleString()
        },
        isPhone() {
            return this.$vuetify.breakpoint.xsOnly
        },
        phoneNoTicketsStyle() {
            const subtractNum = this.isSelect ? 522 : 622
            return {
                'height': `calc(100vh - ${subtractNum}px)`,
                'color': '#a7a7a7',
                'padding-top': `calc((100vh - ${subtractNum + 30}px) / 2)`,
                'text-align': 'center'
            }
        },
    },
    watch: {
        list() {
            this.isSelectAll = false
        },
        selected(values) {
            if (this.isSelect) {
                if (values.length == this.list.length) {
                    this.isSelectAll = true
                }
            }
        }
    },
    methods: {
        select(value) {
            if (this.isSelect) {
                const index = this.selected.indexOf(value)
                let thisSelected = this.selected

                if (!~index) {
                    thisSelected.push(value)
                } else {
                    thisSelected.splice(index, 1)
                }
                this.$emit("change", thisSelected)
            }
        },
        comparator(value) {
            if (this.isSelect) {
                return ~this.selected.indexOf(value)
            } else {
                return false
            }
        },
        selectClass(id) {
            return `${this.comparator(id) || !this.isSelect ? 'selected' : 'select'}-card${this.isSelect ? '' : '_noHover'}`
        },
        phoneHeightStyle(type) {
            const subtractNum = (this.isSelect ? 420 : 505) + (type == 'Scroll' ? 95 : 0) - this.phoneAddHeight
            return {'max-height': `calc( 100vh - ${subtractNum}px)`}
        },
        pcHeightStyle(type) {
            const subtractNum = this.pcHeight + (type == 'Scroll' ? 8 : 0) - (this.shadow ? 0 : 72)
            return {
                'maxHeight': `calc( 100vh - ${subtractNum}px)`,
                'minHeight': `calc( 100vh - ${subtractNum}px)`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.theme--light.v-sheet {
    background-color: transparent;
}

.col-4, .col-6, .col-8, .col-12 {
    padding: 10px 6px;
}
.row {
    margin: -12px 0;
}
.v-list {
    padding: 0;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 8px;
}
.total-transactions {
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 0.7rem;
    border: 1px solid var(--v-secondary-lighten5);
    border-radius: 25px;
    position: relative;

    span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -53%);
    }
}
.scroll {
    overflow: overlay;
}

// PC select
.select-card, .selected-card, .select-card_noHover, .selected-card_noHover {
    padding: 12px;
    border-radius: 16px;
    box-shadow: 3px 3px 9px 0px #00000030;
    transition: all 0.3s;

    p {
        font-size: .9rem;
    }
}
.select-card, .select-card_noHover {
    background-color: white;
    border: 2px solid var(--v-accent-lighten5);
}

.selected-card, .selected-card_noHover {
    background-color: white;
    border: 2px solid var(--v-card-base);
}

.select-card:hover, .selected-card:hover {
    background-color: var(--v-card-lighten5);
}
.select-card:hover {
    border: 2px solid var(--v-card-lighten5);
}

// Mobile
.select {
    overflow-y: auto;
    overflow-x: hidden;

    &_item {
        position: relative;
        cursor: pointer;
        height: 124px;
        padding: 8px 4px 4px 4px !important;
    }

    &_touchBox {
        position: absolute;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        margin: -16px 16px 16px -16px;
        z-index: 1;
    }

    &_bg {
        position: absolute;
        width: -webkit-fill-available;
        height: 124px;
        margin: -16px 0 7px -16px;
        opacity: 0.2;
        transition: all 0.3s;
    }

    &_text {
        position: relative;
        width: -webkit-fill-available;
    }
}
</style>
